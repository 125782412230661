i {
	&.icon-xs {
		font-size: $font-size-icon-xs;
	}
	&.icon-sm {
		font-size: $font-size-icon-sm;
	}
	&.icon-md {
		font-size: $font-size-icon-md;
	}
	&.icon-lg {
		font-size: $font-size-icon-lg;
	}
}
