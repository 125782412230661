.animated {
  visibility: hidden;
}
// Animate.scss imports
.fadeIn {
  @include fadeIn();
}
.fadeInUp {
  @include fadeInUp();
}
.fadeInDown {
  @include fadeInDown();
}
.fadeInLeft {
  @include fadeInLeft();
}
.fadeInRight {
  @include fadeInRight();
}
.zoomInDown {
  @include zoomInDown();
}
.bounceInRight {
  @include bounceInRight();
}
