main > section:not(.call-to-action-row) {
  padding: 60px 0;

  @media (min-width: $screen-sm-min) {
    padding: 120px 0;
  }

  &.properties-grid-block {
    & + .properties-grid-block {
      padding-top: 0;

      @media (min-width: $screen-md-min) {
        padding-top: $grid-gutter-width;
      }
    }
  }

  // Introduction text blocks
  .introduction {
    padding-bottom: 20px;

    @media (min-width: $screen-sm-min) {
      padding-bottom: 40px;
    }
  }
}

// Headings
.section-heading {
	padding: 0 0 50px;
	margin-bottom: 0;
	position: relative;
  text-align: center;

  i {
    margin-top: 30px;
    display: inline-block;
    color: $brand-secondary;
  }

	/*
  &:after {
		content: " ";
		position: absolute;
		bottom: 0;
		width: 60px;
		border-top: 1px solid $brand-secondary;
		left: 50%;
		transform: translateX(-50%);
	}
  */

	h1, h2 {
		margin: 0;
	}

  // Sub heading
  h3 {
    @include base-font(
      $font-family: $font-family-serif,
      $style: italic,
      $size: $font-size-large-desktop,
      $color: $gray-light
    );

    margin: 30px 0 0;
  }
}
