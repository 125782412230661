/* Reset `button` and button-style `input` default styles */
@mixin button-reset() {
  outline: none;
  -webkit-appearance: button; /* for input */
  -webkit-user-select: none; /* for button */
     -moz-user-select: none;
      -ms-user-select: none;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}
/* Reset `button` and button-style `input` default styles */
.button-reset {
  @include button-reset();
}

// Button variants
@mixin button-variant(
  $color,
  $color-hover,
  $background-color,
  $background-color-hover,
  $border-color,
  $border-color-hover
) {
  color: $color;
  background-color: $background-color;
  border-color: $border-color;

  &:focus,
  &.focus {
    color: $color-hover;
    background-color: $background-color-hover;
      border-color: $border-color-hover;
  }
  &:hover {
    color: $color-hover;
    background-color: $background-color-hover;
      border-color: $border-color-hover;
  }
}

// Button sizes
@mixin button-size(
  $padding-vertical,
  $padding-horizontal,
  $font-size,
  $line-height,
  $border-radius,
  $border-width
) {
  padding: $padding-vertical $padding-horizontal;
  font-size: $font-size;
  line-height: $line-height;
  border-radius: $border-radius;
  border-width: $border-width
}
