.property-introduction-container {
  ul.property-features {
    @include list-unstyled();
    @include text-align-left($alignment-min-size: 'all');
    @include text-align-center($alignment-min-size: 'sm');

    @media (min-width: $screen-sm-min) {
      border-top: 1px solid $gray-lighter;
      border-bottom: 1px solid $gray-lighter;
      padding: 10px 0;
      margin-bottom: 30px;
    }

    li {
      font-weight: bold;
      font-family: $font-family-serif;
      font-style: italic;
      text-transform: capitalize;
      color: $brand-primary;
      border-bottom: 1px solid $brand-primary;
      padding: 10px 0;

      &:first-child {
        border-top: 1px solid $brand-primary;
      }

      @media (min-width: $screen-sm-min) {
        padding-right: 5px;
        display: inline-block;
        border: none;

        &:first-child {
          border: none;
        }

        &:after {
          content: "|";
          margin-left: 10px;
        }

        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }
  }
}
