.widget-floating-call-to-action {
  position: fixed;
  top: $navbar-fuelled-mobile-scrolled-height + $navbar-fuelled-mobile-scrolled-border-bottom-width;
  right: 0;
  z-index: $widget-floating-call-to-action-mobile-z-index;
  width: $widget-floating-call-to-action-mobile-width;
  overflow-y: scroll;
  border: none;

  @media (min-width: $screen-sm-min) {
    width: $widget-floating-call-to-action-desktop-width;
    top: $navbar-fuelled-desktop-scrolled-height + $navbar-fuelled-desktop-scrolled-border-bottom-width;
    z-index: $widget-floating-call-to-action-desktop-z-index;
  }

  .widget-inner {
    padding: $widget-floating-call-to-action-mobile-padding;
    text-align: center;

    @media (min-width: $screen-sm-min) {
      padding: $widget-floating-call-to-action-desktop-padding;
      //height: $navbar-fuelled-desktop-scrolled-height;
    }

    // Top level link
    .open-form {
      position: relative;
    }

    a {
      color: $widget-floating-call-to-action-text-color;

      &:hover,
      &:active,
      a:focus {
        text-decoration: none;
      }

      &.widget-close {
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
    }

    h3 {
      font-size: $widget-floating-call-to-action-mobile-title-size;
      margin: 0;
      text-transform: $widget-floating-call-to-action-desktop-title-text-transform;

      @media (min-width: $screen-sm-min) {
        font-size: $widget-floating-call-to-action-desktop-title-size;
      }
    }

    .widget-text {
      padding: 10px 0;
    }

    .widget-form {
      text-align: left;

      textarea {
        max-height: 100px;
      }
    }
  }
}
