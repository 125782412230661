body {
	@include base-font(
		$color: lighten($gray-light, 20%)
	);
}
// Font for mobile
h1, .h1 {
	font-size: $font-size-h1-mobile;
	@include heading-font(
		$color: $brand-primary
	);

	.hero-unit-container & {
		font-size: $font-size-hero-h1-mobile;
	}
}
h2, .h2 {
	font-size: $font-size-h2-mobile;
	@include heading-font(
		$text-transform: uppercase,
		$weight: 700,
		$color: $brand-primary
	);

	.hero-unit-container & {
		font-size: $font-size-hero-h2-mobile;
	}
}
h3, .h3 {
	font-size: $font-size-h3-mobile;
}
h4, .h4 {
	font-size: $font-size-h4-mobile;
}
h5, .h5 {
	font-size: $font-size-h5-mobile;
}
h6, .h6 {
	font-size: $font-size-h6-mobile;
}
p, li {
	font-size: $font-size-base-mobile;
	line-height: $line-height-base;
}
p.leader-text {
	font-size: $font-size-large-mobile;
}

// Font for tablet and above
@media (min-width: $screen-sm-min) {
	h1, .h1 {
		font-size: $font-size-h1-desktop;

		.hero-unit-container & {
			font-size: $font-size-hero-h1-desktop;
		}
	}
	h2, .h2 {
		font-size: $font-size-h2-desktop;

		.hero-unit-container & {
			font-size: $font-size-hero-h2-desktop;
		}
	}
	h3, .h3 {
		font-size: $font-size-h3-desktop;
	}
	h4, .h4 {
		font-size: $font-size-h4-desktop;
	}
	h5, .h5 {
		font-size: $font-size-h5-desktop;
	}
	h6, .h6 {
		font-size: $font-size-h6-desktop;
	}
	p, li {
		font-size: $font-size-base;
	}
	p.leader-text {
		font-size: $font-size-large-desktop;
	}
}
