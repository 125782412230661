.parallax-container {
  //min-height: 250px;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: $screen-sm-min) {
    //min-height: 350px;
    background-attachment: fixed;
  }

  &.parallax-inactive {
      background-attachment: scroll;
  }
}
