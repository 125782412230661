/**
 * Navigation Menu
 * Applied to <nav> element that contains the list items
 *
 */

@mixin navigation-menu(
	$mobile-direction: 'vertical',
	$desktop-direction: 'horizontal'
) {
	ul {
		@include list-unstyled();

		@if $mobile-direction == 'vertical' {
      li {
			  float: none;
			  display: block;
      }
		}

		@if $mobile-direction == 'horizontal' {
      li {
			  float: none;
			  display: inline-block;
      }
		}

		@if $desktop-direction == 'vertical' {
      li {
			  @media (min-width: $screen-sm-min) {
  				float: none;
				  display: block;
        }
			}
		}
		@if $desktop-direction == 'horizontal' {
      li {
			  @media (min-width: $screen-sm-min) {
  				float: none;
				  display: inline-block;
        }
			}
		}
	}
}
