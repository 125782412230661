.gallery-container {
	@include gallery-block(
		$mobile-row-gutter: 0,
	  $mobile-block-width: 12,
	  $mobile-block-gutter: 0,
	  $tablet-row-gutter: 0,
	  $tablet-block-width: 4,
	  $tablet-block-gutter: 0,
	  $desktop-row-gutter: 0,
	  $desktop-block-width: 3,
	  $desktop-block-gutter: 0
	);
	.gallery { // ul

		&.lightbox-gallery {
			// Hide the 'link' icon
			.f-icon-link {
				display: none;
			}
		}

		&:not(.lightbox-gallery) {
			.f-icon-expand {
				display: none;
			}
		}

		&.regular-gallery {
      li {
        border-width: $gallery-item-border-mobile-width;
        border-color: $gallery-item-border-mobile-color;
        border-style: $gallery-item-border-mobile-style;

        @media (min-width: $screen-sm-min) {
          border-width: $gallery-item-border-desktop-width;
          border-color: $gallery-item-border-desktop-color;
          border-style: $gallery-item-border-desktop-style;
        }
      }
    }

		li {
			a {
				color: $gallery-link-color;

				&:hover {
					color: $gallery-link-color-hover;
				}

				// target only <figure> inside link
				figure {
					&:hover {
						i {
							opacity: 1;
						}
					}
				}
			}

			figure {
				position: relative;
				overflow: hidden;
				//@include no-flicker();

				&:hover {
					img {
						transform: scale(1.1);
					}

					.archive-overlay {
						background: rgba($gray-base, 0.8);

						h4 {
							top: 50%;
						}
					}
				}

				i {
					position: absolute;
					z-index: 1000;
					top: 10px;
					right: 10px;
					opacity: 0;
					transition: all 0.3s;
				}

				img {
					transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
					width: 100%;
					//@include no-flicker();
				}

				.archive-overlay {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background: rgba($gray-base, 0.3);
					transition: all 0.3s;

					h4 {
						margin: 0;
						position: absolute;
						top: 50%;
						left: 0;
						right: 0;
						width: 90%;
						margin: 0 auto;
						transform: translateY(-50%);
						text-align: center;
						text-transform: $gallery-link-text-transform;
						color: $white;

						@media (min-width: $screen-sm-min) {
							transition: all 1s cubic-bezier(0.23, 1, 0.32, 1) 0s;
							top: 200%;
						}
					}
				}
			}
		}
	}

	.full-gallery-link {
		padding-top: 40px;
		padding-bottom: 40px;
	}
}
