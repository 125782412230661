.hero-unit-container {
	@include hero();
	@include hero-slider();
	position: relative;
	text-align: center;
	padding: 0;
	background-color: $brand-primary;

	.slide-inner {
		position: relative;

		// Logo
		.brand {
			display: none;

			@media (min-width: $screen-sm-min) {
				display: block;
			}

			img {
				position: absolute;
				top: 20px;
				left: 0;
				right: 0;
				margin: 0 auto;

				.fixed-header-overlay:not(.bottom-positioned-header) & {
					top: $navbar-fuelled-desktop-height + 20px;
				}
			}
		}

		// Social icons
		.hero-social-buttons-container {
			position: absolute;
    	left: 0;
    	width: 100%;
    	bottom: 20px;
			text-align: center;

			@media (min-width: $screen-sm-min) {
				bottom: $navbar-fuelled-desktop-height + 10px;
			}

			li {
				margin: 0 5px;
			}
		}

		.hero-call-to-action-button-container {
			padding: 20px 0 10px;
		}

		// <img> container
		.hero-foreground-image-container {

			img {
				max-height: 240px;

				@media (min-width: $screen-md-min) {
					max-height: 320px;
				}
			}
		}

		// video
		.hero-video {
			padding: 10px 0;
			margin: 0 auto;

			@media (min-width: $screen-xs-min) {
				max-width: 60%;
			}

			@media (min-width: $screen-sm-min) {
				max-width: 65%;
			}

			@media (min-width: $screen-md-min) {
				max-width: 55%;
			}

			@media (min-width: $screen-lg-min) {
				max-width: 45%;
			}
		}

		// Call to action elements
		.hero-call-to-action-form {
			padding: 20px;
			margin-top: 20px;
			background-color: rgba($gray-base, 0.6);

			.gform_footer {
				button {
					@media (min-width: $screen-xs-min) {
						float: none;
					}
				}
			}
		}
		.hero-call-to-action-sub-text {
			font-style: italic;
			font-family: $font-family-serif;
		}
	}
}
