@mixin gallery-block(
  $mobile-row-gutter: $grid-gutter-width,
  $mobile-block-width: 12,
  $mobile-block-gutter: $grid-gutter-width,
  $tablet-row-gutter: $grid-gutter-width,
  $tablet-block-width: 6,
  $tablet-block-gutter: $grid-gutter-width,
  $desktop-row-gutter: $grid-gutter-width,
  $desktop-block-width: 4,
  $desktop-block-gutter: $grid-gutter-width
) {
  ul {
    @include list-unstyled();
    @include make-row($desktop-row-gutter);
		margin: 0;
		text-align: center;

    li { // .gallery-item
      max-width: 100%;
      font-size: 0;
      line-height: 0;

      @include make-xs-column($mobile-block-width, $mobile-block-gutter);

      @media (min-width: $screen-sm-min) {
        @include make-sm-column($tablet-block-width, $tablet-block-gutter);
      }
      @media (min-width: $screen-md-min) {
        @include make-md-column($desktop-block-width, $desktop-block-gutter);
      }

      // Masonry Classes
      &.grid-sizer,
      &.gallery-w1 {
        @include make-xs-column(6, $mobile-block-gutter);

        @media (min-width: $screen-sm-min) {
          @include make-sm-column(3, $tablet-block-gutter);
        }
      }

      &.gallery-w2 {
        @include make-xs-column(12, $mobile-block-gutter);

        @media (min-width: $screen-sm-min) {
          @include make-sm-column(6, $tablet-block-gutter);
        }
      }

      img {
        vertical-align: top;
        @include img-responsive();
      }
    }
  }
}
