html, body {
  height: 100%;
  width: 100%;
}

// Body
body {
  &:not(.has-hero) {
    padding-top: $navbar-fuelled-mobile-height + 20px;

    @media (min-width: $grid-float-breakpoint) {
      padding-top: $navbar-fuelled-desktop-height + 20px;
    }
  }
}

// Selections
::selection {
  background-color: $brand-secondary;
  color: $white;
}
