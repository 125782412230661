.contact-block {
  .address-container {
    padding-top: 30px;
    margin-top: 30px;

    @media (min-width: $screen-sm-min) {
      padding-top: 60px;
      margin-top: 60px;
      border-top: 1px solid $gray-lighter;
    }

    h3 {
      margin-top: 0;
    }

    .address,
    .phone {
      padding-top: 30px;
    }
  }
  /*
  .phone {
    padding-top: 20px;
  }
  */
}
