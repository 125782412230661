.content-info {
  padding-top: $footer-fuelled-mobile-vertical-padding/2;
  padding-bottom: $footer-fuelled-mobile-vertical-padding/2;
  background-color: $footer-fuelled-mobile-bg;
  color: $footer-fuelled-mobile-color;
  border-top: $footer-fuelled-mobile-border-top;
  font-size: $footer-fuelled-mobile-font-size;


  @media (min-width: $screen-sm-min) {
    padding-top: $footer-fuelled-desktop-vertical-padding/2;
    padding-bottom: $footer-fuelled-desktop-vertical-padding/2;
    background-color: $footer-fuelled-desktop-bg;
    color: $footer-fuelled-desktop-color;
    border-top: $footer-fuelled-desktop-border-top;
    font-size: $footer-fuelled-desktop-font-size;
  }

  .row .row {
    padding-bottom: 10px;

    &:last-child {
      padding-bottom: 0;
    }
  }

  .footer-attribution {
    a {
      color: $footer-fuelled-desktop-link-color;

      &:hover,
      &:focus {
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;
      }
    }
  }
}

.sub-footer {
  padding-top: $sub-footer-fuelled-mobile-vertical-padding/2;
  padding-bottom: $sub-footer-fuelled-mobile-vertical-padding/2;
  background-color: $sub-footer-fuelled-mobile-bg;
  color: $sub-footer-fuelled-mobile-color;

  @media (min-width: $screen-sm-min) {
    padding-top: $sub-footer-fuelled-desktop-vertical-padding/2;
    padding-bottom: $sub-footer-fuelled-desktop-vertical-padding/2;
    background-color: $sub-footer-fuelled-desktop-bg;
    color: $sub-footer-fuelled-desktop-color;
  }

  ul {
    @include list-unstyled();
    margin: 0;

    li {
      display: inline-block;
    }
  }

  // <nav>
  .footer-nav {

    li {
      @media (min-width: $screen-sm-min) {
        padding-right: 5px;
        display: inline-block;

        &:after {
          content: "|";
          margin-left: 10px;
          color: $sub-footer-fuelled-mobile-link-color;
        }

        &:last-child {
          &:after {
            content: "";
          }
        }

        &:first-child {
          padding: 0;
        }
      }

      a {
        color: $sub-footer-fuelled-mobile-link-color;
        text-transform: $sub-footer-fuelled-mobile-link-text-transform;
        padding: $sub-footer-fuelled-mobile-link-padding;
        font-size: $sub-footer-fuelled-mobile-link-size;

        &:hover,
        &:focus {
          color: $sub-footer-fuelled-mobile-link-color-hover;
        }

        @media (min-width: $screen-sm-min) {
          color: $sub-footer-fuelled-desktop-link-color;
          text-transform: $sub-footer-fuelled-desktop-link-text-transform;
          padding: $sub-footer-fuelled-desktop-link-padding;
          font-size: $sub-footer-fuelled-desktop-link-size;

          &:hover,
          &:focus {
            color: $sub-footer-fuelled-desktop-link-color-hover;
          }
        }
      }
    }
  }

  // <ul>
  .hero-social-buttons {

    li {
      margin: 0 10px;
      line-height: 0;
    }

    [class^="f-icon-"] {
      font-size: $sub-footer-fuelled-mobile-social-icon-size;
      color: $sub-footer-fuelled-mobile-social-icon-color;

      &:hover,
      &:focus {
        color: $sub-footer-fuelled-mobile-social-icon-color-hover;
      }

      @media (min-width: $screen-sm-min) {
        font-size: $sub-footer-fuelled-desktop-social-icon-size;
        color: $sub-footer-fuelled-desktop-social-icon-color;

        &:hover,
        &:focus {
          color: $sub-footer-fuelled-desktop-social-icon-color-hover;
        }
      }
    }
  }
}
