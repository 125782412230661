.accordion-container {
  .panel {
    border: 1px solid $brand-secondary;
    box-shadow: none;
    border-radius: $border-radius-base;
  }

  .panel-heading {
    .panel-title {
      @include heading-font(
        $color: $brand-secondary,
        $text-transform: uppercase
      );
      padding: 0;

      span {
        padding: 10px 10px 10px 0;
        display: block;
        cursor: pointer;

        i {
          float: right;
        }

        .f-icon-plus {
          display: none;
        }
        .f-icon-minus {
          display: inline-block;
        }

        &.collapsed {
          .f-icon-plus {
            display: inline-block;
          }
          .f-icon-minus {
            display: none;
          }
        }
      }
    }

    // Opened accordion
    +.panel-collapse>.panel-body {
      border-top: 1px solid $brand-secondary;
    }
  }
}
