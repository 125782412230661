// Page Loader div
.page-overlay {
  &.loaded {
    .loader-inner {
      display: none;
    }
  }

  .loader-inner {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;

    span {
      font-size: $loader-text-size;
      color: $loader-text-color;
    }
  }
}
