// Sticky header waypoint (which gets moved according to height of nav)
#sticky-header-waypoint {
  position: absolute;
  //top: 100%; // Manually set for YSL
}

// Full screen bottom waypoint (which gets moved to the bottom of a full screen element)
#full-screen-bottom-waypoint {
  position: absolute;
  top: 100%;
}
