section.widget {
  padding: 0;

  .widget-header {
    position: relative;
  }

  .widget-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: $widget-close-icon-mobile-size;
    line-height: 1;

    @media (min-width: $screen-sm-min) {
      font-size: $widget-close-icon-desktop-size;
    }

    &:hover {
      text-decoration: none;
    }
  }
}
