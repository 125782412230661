.call-to-action-row:not(.v-align-container) {
  padding: 20px;

  @media (min-width: $screen-sm-min) {
    padding: 40px 20px;
  }
}
.call-to-action-row {
  position: relative;
  @include make-row();

  h1 {
    @include heading-font($color: inherit);
    margin-top: 0;
  }

  p {
    @include base-font($size: $font-size-large);
  }

  .foreground-image {
    display: inline-block;
  }
}
/*
.call-to-action-row-style-one {
  padding: 20px 0;

  @media (min-width: $screen-sm-min) {
    padding: 40px 0;
  }
}
*/
