.property-price-inner {
  padding: 40px;
  border: 2px solid $gray-lighter;

  .pricing-intro {
    color: $gray-light;
    margin-top: 0;
  }

  .pricing-and-frequency {
    @include heading-font(
      $color: $brand-primary,
      $text-transform: uppercase
    );

    font-size: $font-size-huge-mobile;

    @media (min-width: $screen-sm-min) {
      font-size: $font-size-huge-desktop;
    }
  }

  .pricing-sub-text {
    @include base-font(
      $font-family: $font-family-serif,
      $style: italic,
      $color: $brand-secondary
    );
  }

  .property-price-buttons {
    margin-top: 30px;

    a {
      display: block;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      @media (min-width: $screen-sm-min) {
        display: inline-block;
        margin-bottom: 0;
      }
    }
  }
}
