// Alignment
@mixin text-align-left($alignment-min-size: 'all') {
  @if $alignment-min-size == 'all' {
    text-align: left;
  }
  @else if $alignment-min-size == 'xs' {
    @media (min-width: $screen-xs-min) {
        text-align: left;
    }
  }
  @else if $alignment-min-size == 'sm' {
    @media (min-width: $screen-sm-min) {
        text-align: left;
    }
  }
  @else if $alignment-min-size == 'md' {
    @media (min-width: $screen-md-min) {
      text-align: left;
    }
  }
  @else if $alignment-min-size == 'lg' {
    @media (min-width: $screen-lg-min) {
      text-align: left;
    }
  }
}

@mixin text-align-right($alignment-min-size: 'all') {
  @if $alignment-min-size == 'all' {
    text-align: right;
  }
  @else if $alignment-min-size == 'xs' {
    @media (min-width: $screen-xs-min) {
      text-align: right;
    }
  }
  @else if $alignment-min-size == 'sm' {
    @media (min-width: $screen-sm-min) {
      text-align: right;
    }
  }
  @else if $alignment-min-size == 'md' {
    @media (min-width: $screen-md-min) {
      text-align: right;
    }
  }
  @else if $alignment-min-size == 'lg' {
    @media (min-width: $screen-lg-min) {
      text-align: right;
    }
  }
}

@mixin text-align-center($alignment-min-size: 'all') {
  @if $alignment-min-size == 'all' {
    text-align: center;
  }
  @else if $alignment-min-size == 'xs' {
    @media (min-width: $screen-xs-min) {
      text-align: center;
    }
  }
  @else if $alignment-min-size == 'sm' {
    @media (min-width: $screen-sm-min) {
      text-align: center;
    }
  }
  @else if $alignment-min-size == 'md' {
    @media (min-width: $screen-md-min) {
      text-align: center;
    }
  }
  @else if $alignment-min-size == 'lg' {
    @media (min-width: $screen-lg-min) {
      text-align: center;
    }
  }
}

// Alignment for all screen sizes
.text-align-left {
  @include text-align-left();
}
.text-align-center {
  @include text-align-center();
}
.text-align-right {
  @include text-align-right();
}

// Alignment for specific screen sizes
.text-align-left-xs-min {
  @include text-align-left(xs);
}
.text-align-left-sm-min {
  @include text-align-left(sm);
}
.text-align-left-md-min {
  @include text-align-left(md);
}
.text-align-left-lg-min {
  @include text-align-left(lg);
}
.text-align-right-xs-min {
  @include text-align-right(xs);
}
.text-align-right-sm-min {
  @include text-align-right(sm);
}
.text-align-right-md-min {
  @include text-align-right(md);
}
.text-align-right-lg-min {
  @include text-align-right(lg);
}
.text-align-center-xs-min {
  @include text-align-center(xs);
}
.text-align-center-sm-min {
  @include text-align-center(sm);
}
.text-align-center-md-min {
  @include text-align-center(md);
}
.text-align-center-lg-min {
  @include text-align-center(lg);
}

// Vertical alignment
.v-align-container {
  width: 100%;
  height: 100%;
  display: table;
  margin: 0;
  padding: 0;

  // Applied to a wrapper of the content you want to center
  .v-align-inner {
    vertical-align: middle;
    display: table-cell;
    padding: 0;
    margin: 0;
    line-height: 0;

    > * {
      line-height: initial; // Reset the vertical alignment on child items
    }
  }
}
// Absolute positioned vertical center
.square-container {

  @media (min-width: $screen-sm-min) {
    height: 100%;
    padding-bottom: 100%;
    position: relative;
    overflow: hidden;

    .square-inner {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
    }
  }
}

// To be tested - uses flexbox
.vertical-center {
  min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
}
