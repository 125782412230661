.page-overlay {
  background: $brand-primary;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-index-loader;

  &.loaded {
    opacity: 0.8;
    z-index: $z-index-overlay;
  }
}
.section-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // Background colour set via ACF
}
