/**
 * Global header
 * Applied to the parent of the site <header> element
 *
 */
@mixin site-header(
  $navigation-menu-desktop-direction: 'horizontal',
  $navigation-menu-mobile-direction: 'vertical'
) {
	min-height: 1px;
	z-index: $zindex-navbar-fixed;
	@include clearfix;
  background-color: $navbar-fuelled-mobile-bg;
  border-bottom: $navbar-fuelled-mobile-border-bottom;

  @media (min-width: $grid-float-breakpoint) {
    background-color: $navbar-fuelled-desktop-bg;
    border-bottom: $navbar-fuelled-desktop-border-bottom;
  }

	// Apply different set of styles to fixed header
	// This will be defined via JS or in cell.php
	&.navbar-fixed-top {
		// Pull in Bootstrap styles
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
	}

	// Bottom positioned (not fixed) navbars
	&.navbar-positioned-bottom {
    @media (min-width: $grid-float-breakpoint) {

      body.bottom-positioned-header & {
        position: absolute;
    		bottom: 0;
        right: 0;
        left: 0;
      }
    }
	}

	// Apply styles once the navbar-scolled class is added (when a waypoint is hit)
	&.navbar-scrolled {
    background-color: $navbar-fuelled-mobile-scrolled-bg;
    border-bottom: $navbar-fuelled-mobile-scrolled-border-bottom;

    @media (min-width: $grid-float-breakpoint) {
      background-color: $navbar-fuelled-desktop-scrolled-bg;
      border-bottom: $navbar-fuelled-desktop-scrolled-border-bottom;
    }

		// <header>
		.navbar-header-fuelled {
			height: $navbar-fuelled-mobile-scrolled-height;

			@media (min-width: $grid-float-breakpoint) {
				height: $navbar-fuelled-desktop-scrolled-height;
			}

			.brand {
				>img {
					height: $navbar-fuelled-mobile-scrolled-height;
          padding-top: $navbar-fuelled-mobile-scrolled-logo-vertical-padding/2;
          padding-bottom: $navbar-fuelled-mobile-scrolled-logo-vertical-padding/2;

					@media (min-width: $grid-float-breakpoint) {
						height: $navbar-fuelled-desktop-scrolled-height;
            padding-top: $navbar-fuelled-desktop-scrolled-logo-vertical-padding/2;
            padding-bottom: $navbar-fuelled-desktop-scrolled-logo-vertical-padding/2;
					}
				}
			}

      .navbar-header-inner {
        background-color: $navbar-fuelled-mobile-scrolled-bg;
        border-bottom: $navbar-fuelled-mobile-scrolled-border-bottom;

        @media (min-width: $grid-float-breakpoint) {
          background-color: transparent;
          border-bottom: transparent;
        }
      }

			// <nav.navbar-collapse>
			.navbar-collapse {
        // No margin needed for mobile as we want it at the top
        margin-top: 0;

				// Desktop nav <nav.navbar-collapse>
				@media (min-width: $grid-float-breakpoint) {
          margin-top: (
            $navbar-fuelled-desktop-scrolled-height -
            $navbar-fuelled-desktop-scrolled-border-bottom-width -
            $navbar-fuelled-desktop-link-vertical-padding -
            $navbar-fuelled-desktop-link-size -
            $navbar-fuelled-desktop-link-border-top-width -
            $navbar-fuelled-desktop-link-border-bottom-width
          )/2;
				}
			}
		}
	}

	// <header>
	.navbar-header-fuelled {
		transition: height 0.3s;
    height: $navbar-fuelled-mobile-height;
    border-radius: 0;

    @media (min-width: $grid-float-breakpoint) {
      height: $navbar-fuelled-desktop-height;
    }

    .navbar-header-inner {
      background-color: $navbar-fuelled-mobile-bg;
      border-bottom: $navbar-fuelled-mobile-border-bottom;

      @media (min-width: $grid-float-breakpoint) {
        background-color: transparent;
        border-bottom: transparent;
      }
    }

		// <div> containing logo
		.brand {
			height: auto;

			// <img> (logo)
			>img {
				transition: height 0.3s;
				@include img-responsive($display: inline-block);
				max-width: $navbar-fuelled-mobile-logo-max-width;
				height: $navbar-fuelled-mobile-height;
        padding-top: $navbar-fuelled-mobile-logo-vertical-padding/2;
        padding-bottom: $navbar-fuelled-mobile-logo-vertical-padding/2;

				@media (min-width: $grid-float-breakpoint) {
					max-width: $navbar-fuelled-desktop-logo-max-width;
					height: $navbar-fuelled-desktop-height;
          padding-top: $navbar-fuelled-desktop-logo-vertical-padding/2;
          padding-bottom: $navbar-fuelled-desktop-logo-vertical-padding/2;
				}
			}
		}
	}

	// <nav> navbar
	nav.navbar-collapse {
		transition: all 0.3s;

    @media (min-width: $grid-float-breakpoint) {
      margin-top: (
        $navbar-fuelled-desktop-height -
        $navbar-fuelled-desktop-border-bottom-width -
        $navbar-fuelled-desktop-link-vertical-padding -
        $navbar-fuelled-desktop-link-size -
        $navbar-fuelled-desktop-link-border-top-width -
        $navbar-fuelled-desktop-link-border-bottom-width
      )/2;
    }

		// Set direction of nav menu
		@include navigation-menu(
			$desktop-direction: $navigation-menu-desktop-direction,
			$mobile-direction: $navigation-menu-mobile-direction
		);

		// <ul> of the menu
		.navbar-nav {

			li {
        font-size: 0; // Reset the font size as it's specified inside the link and we need this to be accurate for positioning

        &.active {
          a {
            background-color: $navbar-fuelled-mobile-link-hover-bg;
            color: $navbar-fuelled-mobile-link-hover-color;
            border-color: $navbar-fuelled-mobile-link-hover-border-color;
            border-width: $navbar-fuelled-mobile-link-hover-border-width;
            border-style: $navbar-fuelled-mobile-link-hover-border-style;
            text-decoration: none;

            @media (min-width: $grid-float-breakpoint) {
              background-color: $navbar-fuelled-desktop-link-hover-bg;
              color: $navbar-fuelled-desktop-link-hover-color;
              border-color: $navbar-fuelled-desktop-link-hover-border-color;
              border-width: $navbar-fuelled-desktop-link-hover-border-width;
              border-style: $navbar-fuelled-desktop-link-hover-border-style;
            }
          }
        }

				a {
					padding: $navbar-fuelled-mobile-link-vertical-padding $navbar-fuelled-mobile-link-horizontal-padding;
					font-size: $navbar-fuelled-mobile-link-size;
          line-height: 1;
					text-transform: $navbar-fuelled-mobile-link-text-transform;
					background-color: $navbar-fuelled-mobile-link-bg;
					color: $navbar-fuelled-mobile-link-color;
					border-color: $navbar-fuelled-mobile-link-border-color;
					border-width: $navbar-fuelled-mobile-link-border-width;
					border-style: $navbar-fuelled-mobile-link-border-style;
					display: block;

					&:hover,
          &:active {
						background-color: $navbar-fuelled-mobile-link-hover-bg;
						color: $navbar-fuelled-mobile-link-hover-color;
						border-color: $navbar-fuelled-mobile-link-hover-border-color;
						border-width: $navbar-fuelled-mobile-link-hover-border-width;
						border-style: $navbar-fuelled-mobile-link-hover-border-style;
						text-decoration: none;
					}

					@media (min-width: $grid-float-breakpoint) {
						padding: $navbar-fuelled-desktop-link-vertical-padding $navbar-fuelled-desktop-link-horizontal-padding;
						font-size: $navbar-fuelled-desktop-link-size;
						text-transform: $navbar-fuelled-desktop-link-text-transform;
						background-color: $navbar-fuelled-desktop-link-bg;
						color: $navbar-fuelled-desktop-link-color;
						border-color: $navbar-fuelled-desktop-link-border-color;
						border-width: $navbar-fuelled-desktop-link-border-width;
						border-style: $navbar-fuelled-desktop-link-border-style;
						display: inherit;

						&:hover,
            &:active {
							background-color: $navbar-fuelled-desktop-link-hover-bg;
							color: $navbar-fuelled-desktop-link-hover-color;
							border-color: $navbar-fuelled-desktop-link-hover-border-color;
							border-width: $navbar-fuelled-desktop-link-hover-border-width;
							border-style: $navbar-fuelled-desktop-link-hover-border-style;
						}
					}
				}
			}
		}
	}
}

/**
 * Navbar toggle settings
 * Applied to the <button> toggle containing <span>
 *
 */
@mixin navbar-toggle(
	$toggle-bg: $navbar-fuelled-toggle-bg,
	$toggle-hover-bg: $navbar-fuelled-toggle-hover-bg,
	$toggle-border: $navbar-fuelled-toggle-border,
	$toggle-hover-border: $navbar-fuelled-toggle-hover-border,
	$toggle-border-radius: $border-radius-base,
	$toggle-padding: $navbar-fuelled-toggle-padding,
	$toggle-span-height: $navbar-fuelled-toggle-span-height,
	$toggle-span-width: $navbar-fuelled-toggle-span-width,
	$toggle-span-vertical-spacing: $navbar-fuelled-toggle-span-vertical-spacing,
	$toggle-span-color: $navbar-fuelled-toggle-span-color,
	$toggle-span-hover-color: $navbar-fuelled-toggle-span-hover-color
) {
	margin: 0;
	z-index: $zindex-navbar-fixed;
	position: absolute;
	right: $grid-gutter-width/2;
	top: 50%;
	transform: translateY(-50%);
	background-color: $toggle-bg;
	border: $toggle-border;
	border-radius: $toggle-border-radius;
	padding: $toggle-padding;

	@media (min-width: $grid-float-breakpoint) {
		display: none;
	}

	&:hover {
		background-color: $toggle-hover-bg;
		border: $toggle-hover-border;
	}

	// Lines inside button
	span {
		background-color: $toggle-span-color;
		height: $toggle-span-height;
		width: $toggle-span-width;
		display: block;

		+ span {
			margin-top: $toggle-span-vertical-spacing;
		}

		.navbar-toggle:hover & {
			background-color: $toggle-span-hover-color;
		}
	}
}
