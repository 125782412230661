// All non form buttons
.btn {
	transition: all 0.3s;
	text-transform: uppercase;
	@include button-size($padding-base-vertical, $padding-base-horizontal, $font-size-base, $line-height-base, $btn-border-radius-base, $btn-border-width);
	@include user-select(none);
}

// Button variants
.btn-default {
  @include button-variant($btn-default-color, $btn-default-color-hover, $btn-default-bg, $btn-default-bg-hover, $btn-default-border, $btn-default-border-hover);
}
.btn-primary {
  @include button-variant($btn-primary-color, $btn-primary-color-hover, $btn-primary-bg, $btn-primary-bg-hover, $btn-primary-border, $btn-primary-border-hover);
}
.btn-primary-filled {
  @include button-variant($btn-primary-filled-color, $btn-primary-filled-color-hover, $btn-primary-filled-bg, $btn-primary-filled-bg-hover, $btn-primary-filled-border, $btn-primary-filled-border-hover);
}
.btn-secondary {
  @include button-variant($btn-secondary-color, $btn-secondary-color-hover, $btn-secondary-bg, $btn-secondary-bg-hover, $btn-secondary-border, $btn-secondary-border-hover);
}
.btn-secondary-filled {
  @include button-variant($btn-secondary-filled-color, $btn-secondary-filled-color-hover, $btn-secondary-filled-bg, $btn-secondary-filled-bg-hover, $btn-secondary-filled-border, $btn-secondary-filled-border-hover);
}
.btn-success {
  @include button-variant($btn-success-color, $btn-success-color-hover, $btn-success-bg, $btn-success-bg-hover, $btn-success-border, $btn-success-border-hover);
}
.btn-info {
  @include button-variant($btn-info-color, $btn-info-color-hover, $btn-info-bg, $btn-info-bg-hover, $btn-info-border, $btn-info-border-hover);
}
.btn-warning {
  @include button-variant($btn-warning-color, $btn-warning-color-hover, $btn-warning-bg, $btn-warning-bg-hover, $btn-warning-border, $btn-warning-border-hover);
}
.btn-danger {
  @include button-variant($btn-danger-color, $btn-danger-color-hover, $btn-danger-bg, $btn-danger-bg-hover, $btn-danger-border, $btn-danger-border-hover);
}

// Button sizes
.btn-lg {
  // line-height: ensure even-numbered height of button next to large input
  @include button-size($padding-large-vertical, $padding-large-horizontal, $font-size-large, $line-height-large, $btn-border-radius-large, $btn-border-width);
}
.btn-sm {
  // line-height: ensure proper height of button next to small input
  @include button-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $line-height-small, $btn-border-radius-small, $btn-border-width);
}
.btn-xs {
  @include button-size($padding-xs-vertical, $padding-xs-horizontal, $font-size-small, $line-height-small, $btn-border-radius-small, $btn-border-width);
}

// Hero buttons
