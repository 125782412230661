/* ==========================================================================
Gravity Forms
========================================================================== */

.gform_wrapper {
  .gform_body {
    @include clearfix();

    ul {
      padding: 0;
      list-style: none;
      list-style-image: none!important;
    }

    > ul {
      @include make-row();

      li.gfield {
        margin-bottom: 30px;
        position: relative;

        &.gfield_error {
          color: $brand-danger;

          // Set validation styling
          .validation_message {
            color: $brand-danger;
            font-size: 12px;
            position: absolute;
            top: 3px;
            right: 10px;
          }
        }

        &.gform_hidden {
          margin: 0;
        }

        // Hide no SSL warning
        .gfield_creditcard_warning_message {
          display: none;
        }

        textarea,
        select,
        input {
          border-width: 0 0 2px 0;
          border-color: $brand-primary;
          background-color: transparent;
          color: $gray-darker;
          padding: 10px;
          font-size: 18px;
          font-family: $font-family-serif;
          font-style: italic;
          transition: all 0.3s;

          &:focus,
          &:active {
            outline: none;
            border-color: $brand-secondary;
          }
        }

        textarea,
        input[type=text],
        input[type=number],
        input[type=tel],
        input[type=email]{
          min-width: 100%;
          max-width: 100%;
        }

        > label {
          position: absolute;
          pointer-events: none;
          top: 10px;
          left: 25px;
        }

        label {
          font-size: 18px;
          font-family: $font-family-serif;
          font-style: italic;
          transition: all 0.3s;
          color: $gray-light;

          &.label-active {
            top: -5px;
            left: 25px;
            font-size: 12px;
            font-family: $font-family-sans-serif;
            font-style: normal;
            text-transform: uppercase;
            color: $brand-secondary;
          }

          .gfield_required {
            display: none;
          }
        }

        select {
          padding: 8.5px 5px;
          cursor: pointer;
          background: $select-field-bg;
          -webkit-appearance: none;
          width: 100%;
        }

        // Date of birth
        .clear-multi {
          text-align: center;

          .gfield_date_day,
          .gfield_date_month,
          .gfield_date_year {
            width: 30%;
            display: inline-block;
          }

          .gfield_date_day {
            float: left;
          }

          .gfield_date_year {
            float: right;
          }

          // Sub labels
          label {
            display: none;
          }
        }

        // Total Field
        .ginput_total {
          display: block;
          padding: 15px;
          margin-top: 15px;
          margin-bottom: 15px;
          background-color: $form-total-field-bg;
          color: white;
          text-align: center;
        }

        // Credit card field
        .ginput_complex {

          > span {
            display: block;
          }

          input,
          select {
            margin-bottom: 10px;
          }

          .ginput_cardinfo_left {

            .ginput_card_expiration_container {
              @include clearfix();

              .ginput_card_expiration_month {
                width: 49%;
              }

              .ginput_card_expiration_year {
                width: 49%;
                float: right;
              }

              label {
                display: block;
              }
            }
          }

          .ginput_cardinfo_right {

            .ginput_card_security_code {
              min-width: 85%;
              max-width: 85%;

              @media (min-width: $screen-xs-min) {
                min-width: 90%;
                max-width: 90%;
              }

              @media (min-width: $screen-sm-min) {
                min-width: 92%;
                max-width: 92%;
              }
            }

            span.ginput_card_security_code_icon {
              width: 32px;
              height: 23px;
              background-image: url(../images/gf-creditcard-icons.png);
              background-repeat: no-repeat;
              background-position: 0 -128px;
              position: relative;
              display: -moz-inline-stack;
              display: inline-block;
              zoom: 1;
              float: right;
              top: 8px;
            }
          }
        }
      }
    }

    // Helpers
    >ul {
      li {
        // Remove main label
        &.nolabel {
          label {
            color: transparent;
            font-size: 1px;
            line-height: 16px;
          }
        }

        // Remove main label, but keep sub labels
        &.noprimarylabel {
          >label {
            display: none;
          }
        }
      }
    }

    // Style checkboxes
    .gfield_checkbox {
      li {
        /* Base for label styling */
        input:not(:checked),
        input:checked {
          position: absolute;
          left: -9999px;
        }
        input:not(:checked) + label,
        input:checked + label {
          position: relative;
          padding-left: 48px;
          cursor: pointer;
          left: 0;

          @media (min-width: $screen-xs-min) {
            padding-left: 28px;
          }
        }

        /* checkbox */
        input:not(:checked) + label:before,
        input:checked + label:before {
          content: '';
          position: absolute;
          left: 0;
          top: 2px;
          width: 22px;
          height: 22px;
          border: $form-check-border;
          background: white;
          box-shadow: none;
        }
        /* checked mark */
        input:not(:checked) + label:after,
        input:checked + label:after {
          content: '✔';
          position: absolute;
          top: 0px;
          left: 5px;
          font-size: 16px;
          font-style: normal;
          color: $form-check-color;
          @include transition(all .1s);
        }
        /* checked mark changes */
        input:not(:checked) + label:after {
          opacity: 0;
          transform: scale(0);
        }
        input:checked + label:after {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }

  // Credit card icons
  .gform_card_icon_container {
    margin: 8px 0 6px 0;
    height: 32px;

    .gform_card_icon {
      margin-right: 4px;
      @include hide-text();
      background-image: url(../images/gf-creditcard-icons.png);
      background-repeat: no-repeat;
      width: 36px;
      height: 32px;
      float: left;
    }

    &.gform_card_icon_style1 {
      .gform_card_icon_visa {
        background-position: 0 0;

        &.gform_card_icon_selected {
          background-position: 0 -32px;
        }

        &.gform_card_icon_inactive {
          background-position: 0 -64px;
        }
      }

      .gform_card_icon_mastercard {
        background-position: -36px 0;

        &.gform_card_icon_selected {
          background-position: -36px -32px;
        }

        &.gform_card_icon_inactive {
          background-position: -36px -64px;
        }
      }

      .gform_card_icon_amex {
        background-position: -72px 0;

        &.gform_card_icon_selected {
          background-position: -72px -32px;
        }

        &.gform_card_icon_inactive {
          background-position: -72px -64px;
        }
      }

      .gform_card_icon_discover {
        background-position: -108px 0;

        &.gform_card_icon_selected {
          background-position: -108px -32px;
        }

        &.gform_card_icon_inactive {
          background-position: -108px -64px;
        }
      }

      .gform_card_icon_maestro {
        background-position: -144px 0;

        &.gform_card_icon_selected {
          background-position: -144px -32px;
        }

        &.gform_card_icon_inactive {
          background-position: -144px -64px;
        }
      }

      .gform_card_icon_jcb {
        background-position: -180px 0;

        &.gform_card_icon_selected {
          background-position: -180px -32px;
        }

        &.gform_card_icon_inactive {
          background-position: -180px -64px;
        }
      }
    }

    &.gform_card_icon_style2 {
      .gform_card_icon_visa {
        background-position: 0 -192px;

        &.gform_card_icon_selected {
          background-position: 0 -224px;
        }

        &.gform_card_icon_inactive {
          background-position: 0 -256px;
        }
      }

      .gform_card_icon_mastercard {
        background-position: -36px -192px;

        &.gform_card_icon_selected {
          background-position: -36px -224px;
        }

        &.gform_card_icon_inactive {
          background-position: -36px -256px;
        }
      }

      .gform_card_icon_amex {
        background-position: -72px -192px;

        &.gform_card_icon_selected {
          background-position: -72px -224px;
        }

        &.gform_card_icon_inactive {
          background-position: -72px -256px;
        }
      }

      .gform_card_icon_discover {
        background-position: -108px -192px;

        &.gform_card_icon_selected {
          background-position: -108px -224px;
        }

        &.gform_card_icon_inactive {
          background-position: -108px -256px;
        }
      }

      .gform_card_icon_maestro {
        background-position: -144px -192px;

        &.gform_card_icon_selected {
          background-position: -144px -224px;
        }

        &.gform_card_icon_inactive {
          background-position: -144px -256px;
        }
      }

      .gform_card_icon_jcb {
        background-position: -180px -192px;

        &.gform_card_icon_selected {
          background-position: -180px -224px;
        }

        &.gform_card_icon_inactive {
          background-position: -180px -256px;
        }
      }
    }
  }

  // Footer
  .gform_footer {
    @include clearfix();
    @include text-align-center();

    button {
      width: 100%;

      @media (min-width: $screen-xs-min) { //480px
        width: auto;
      }
    }
  }
}

// Confirmation messages
.gforms_confirmation_message {
  text-align: center;
  font-size: $font-size-large;
  font-weight: bold;
  color: $brand-secondary;
}
