.properties-grid-block {

  .properties-introduction {
    padding: 0 0 20px;

    @media (min-width: $screen-sm-min) {
      padding: 0 0 40px;
    }
  }

  .properties-blocks {
    margin-left: -15px;
    margin-right: -15px;

    @at-root {
      .full-width-container .properties-blocks {
        margin-left: auto;
        margin-right: auto;

        > div {
          @media (min-width: $screen-sm-min) {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }
  }

  .grid-inner {
    background-size: cover;
    background-position: center;
    transition: all 0.3s ease-in-out;
    color: $white;
    position: relative;
    margin-bottom: $grid-gutter-width/2;

    @media (max-width: $screen-xs-max) {
      padding: 10px 0 20px;
    }

    @media (min-width: $screen-sm-min) {
      background-size: 100% 100%;
    }

    @media (min-width: $screen-md-min) {
      margin-bottom: 0;
    }

    &:hover {
      a {
        opacity: 1;
      }

      .grid-overlay {
        @media (min-width: $screen-sm-min) {
          background: rgba($brand-primary, 1);
        }
      }
    }

    .grid-overlay {
      background: rgba($gray-base, 0.4);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: all 0.3s ease-in-out;
    }

    h4 {
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-bottom: 15px;
    }

    .property-sub-text {
      font-family: $font-family-serif;
      font-style: italic;
      padding: 15px 0 0;
      position: relative;
      line-height: 1.2;

      &:before {
        content: " ";
        position: absolute;
        top: 0;
        width: 120px;
        border-top: 1px solid $brand-secondary;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    a {
      transition: all 0.6s;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 10px;

      @media (min-width: $screen-sm-min) {
        position: absolute;
        bottom: 5%;
        transform: translate(-50%);
        margin-top: 0;
      }

      @media (min-width: $screen-md-min) {
        .touch & {
          opacity: 1;
        }

        opacity: 0;
      }
    }
  }
}
