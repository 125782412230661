// Applied to containing element of <a>
@mixin icon-link-reset(
  $mobile-font-size: $font-size-icon-md,
  $desktop-font-size: $font-size-icon-md,
  $color: $brand-primary,
  $hover-color: darken($brand-primary, 10%)
) {
  @include text-hide();

  a {
    display: block;

    &:before {
      font-size: $mobile-font-size;
      color: $color;

      @media (min-width: $screen-sm-min) {
        font-size: $desktop-font-size;
      }
    }

    &:hover {
      text-decoration: none;

      &:before {
        color: $hover-color;
      }
    }
  }
}
