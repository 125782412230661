// <div> containing <header>
.header-container {
	// Inherit global styles
	@include site-header(
		$navigation-menu-desktop-direction: 'horizontal',
		$navigation-menu-mobile-direction: 'vertical'
	);

	body:not(.has-hero) & {
		@extend .navbar-fixed-top;
	}

	// <header>
	.navbar-header-fuelled {
		margin: 0 auto;
		padding-left: $grid-gutter-width/2;
		padding-right: $grid-gutter-width/2;

		// define header widths for different screen sizes
		@media (min-width: $grid-float-breakpoint) {
			width: $container-sm - $grid-gutter-width;
		}

		@media (min-width: $screen-md-min) {
			width: $container-md - $grid-gutter-width;
		}

		@media (min-width: $screen-lg-min) {
			width: $container-lg - $grid-gutter-width;
		}

		// <div> containing logo and nav toggle
		.navbar-header {

		}

		.navbar-header-inner {
			font-size: 0;
		}

		// <a> containing logo
		.navbar-brand {
			margin-left: auto;
			padding: 0;
			float: none;
			max-width: $navbar-fuelled-mobile-logo-max-width;

			@media (min-width: $grid-float-breakpoint) {
				max-width: $navbar-fuelled-desktop-logo-max-width;
			}

			img {
				max-height: $navbar-fuelled-mobile-height;

				@media (min-width: $grid-float-breakpoint) {
					max-height: $navbar-fuelled-desktop-height;
				}
			}
		}

		// Mobile nav toggle <button>
		.navbar-toggle {
			@include navbar-toggle;
		}

		// Mobile nav <nav.navbar-collapse>
		.navbar-collapse {
			background-color: $navbar-fuelled-mobile-nav-collapse-bg;
			margin-left: -$grid-gutter-width/2;
			margin-right: -$grid-gutter-width/2;
		  overflow-x: visible;
		  -webkit-overflow-scrolling: touch;

			@include clearfix;

			// Desktop nav <nav.navbar-collapse>
			@media (min-width: $grid-float-breakpoint) {
				background-color: $navbar-fuelled-desktop-nav-collapse-bg;
				margin-left: 0;
				margin-right: 0;
				width: auto;
			}

		  &.in {
		    overflow-y: auto;

				@media (min-width: $grid-float-breakpoint) {
					overflow-y: visible;
				}
		  }

			&.collapse {
				// Ensure the nav is visible on larger screens
				@media (min-width: $grid-float-breakpoint) {
					display: block !important;
					height: auto !important;
					padding-bottom: 0; // Override default setting
					overflow: visible !important;
				}
			}

			// <ul> containing menu items
			.navbar-nav {
				@include list-unstyled;
				margin-bottom: 0;
			}
		}
	}
}
