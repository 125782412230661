/**
 * Heading font
 *
 */
@mixin heading-font(
    $font-family: $top-level-headings-font-family,
    $weight: $top-level-headings-font-weight,
    $style: normal,
    $line-height: $top-level-headings-line-height,
    $color: inherit,
    $text-transform: capitalize
  ) {

  font-family: $font-family;
  font-weight: $weight;
  font-style: $style;
  line-height: $line-height;
  color: $color;
  text-transform: $text-transform;
}

/**
 * Sub Heading font
 *
 */
@mixin sub-heading-font(
    $font-family: $sub-headings-font-family,
    $weight: $sub-headings-font-weight,
    $style: normal,
    $line-height: $sub-headings-line-height,
    $color: inherit
  ) {

  font-family: $font-family;
  font-weight: $weight;
  font-style: $style;
  line-height: $line-height;
  color: $color;
}

/**
 * Body font
 *
 */
@mixin base-font(
    $font-family: $font-family-base,
    $weight: $font-weight-base,
    $style: normal,
    $line-height: $line-height-base,
    $size: $font-size-base,
    $color: inherit
  ) {

  font-family: $font-family;
  font-weight: $weight;
  font-style: $style;
  line-height: $line-height;
  font-size: $size;
  color: $color;
}
