/**
 * Hero
 * Applied to outer container
 *
 */
@mixin hero() {

	* {
		color: $hero-slider-text-color;
	}

	// Social icons
	.hero-social-buttons {
		@include list-unstyled();
		margin-bottom: 0;

		li {
			display: inline-block;
			@include icon-link-reset(
				$mobile-font-size: $hero-mobile-social-icon-size,
				$desktop-font-size: $hero-desktop-social-icon-size,
				$color: $hero-social-icon-color,
			  $hover-color: $hero-social-icon-hover-color
			);
		}
	}

	.hero-headline {
		font-size: $font-size-hero-h1-mobile;
		margin-top: 0;

		@media (min-width: $screen-sm-min) {
			font-size: $font-size-hero-h1-desktop;
		}
	}

	.hero-sub-headline {
		font-size: $font-size-hero-h2-mobile;
		margin-top: 0;

		@media (min-width: $screen-sm-min) {
			font-size: $font-size-hero-h2-desktop;
		}
	}

	// <img>
	.hero-foreground-image {
		@include img-responsive($display: inline-block);
	}

	// video
	.hero-video {
		text-align: center;

		@media (min-width: $screen-sm-min) {
			margin: 0 auto;
		}

		.play-button {
			font-size: $hero-video-play-icon-size;
			color: $hero-video-play-icon-color;

			&:hover,
			&:visited,
			&:focus {
				text-decoration: none;
				color: $hero-video-play-icon-hover-color;
			}
		}
	}

	// Call to action elements
	.hero-call-to-action-sub-text {
		font-size: $hero-call-to-action-mobile-sub-text-size;
		text-align: center;
		margin-top: 10px;

		@media (min-width: $screen-sm-min) {
			font-size: $hero-call-to-action-desktop-sub-text-size;
		}
	}
}

/**
 * Hero sliders
 * Applied to outer container
 *
 */
@mixin hero-slider() {
	.slide {
		background-size: cover;
		background-position: center center;
		position: relative;

		// Set logo to inline (if it exists inside hero)
		.brand {
			img {
				display: inline-block;
			}
		}
	}

  // Slider navigation
	.slider-control {
		@include button-reset();
		visibility: hidden;

    @media (min-width: $screen-sm-min) {
      position: absolute;
			top: 50%;
			bottom: auto;
			width: auto;
      transform: translateY(-50%);
      float: none;
			background: transparent;
			border: none;
			font-size: 0;
			color: transparent;
			visibility: visible;

			&:before {
				font-size: $hero-slider-desktop-nav-icon-size;
				color: $hero-slider-desktop-nav-icon-color;
			}

			.bottom-positioned-header & {
				margin-top: -$navbar-fuelled-desktop-height/2;
			}
    }
	}

	.slider-prev {
		left: 0;
    float: left;
	}

	.slider-next {
		right: 0;
    float: right;
	}

	// Slider dots
	.slick-dots {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		@include list-unstyled();
		@extend .container;
		padding-left: $grid-gutter-width;
		padding-right: $grid-gutter-width;

		@at-root {
			.bottom-positioned-header & {

				@media (min-width: $grid-float-breakpoint) {
					bottom: $navbar-fuelled-desktop-height;
				}
			}
		}

		li {
			display: inline-block;
			margin-right: 10px;

			&:last-child {
				margin-right: 0;
			}

			&.slick-active {
				button {
					border: 1px solid $hero-slider-dots-active-border-color;
					background: $hero-slider-dots-active-color;
				}
			}

			button {
				border-radius: $hero-slider-dots-border-radius;
				text-indent: -9999px;
				overflow: hidden;
				width: 20px;
				height: 20px;
				border: 1px solid $hero-slider-dots-inactive-border-color;
				background: $hero-slider-dots-inactive-color;

				@media (min-width: $screen-sm-min) {
					width: 14px;
					height: 14px;
				}
			}
		}
	}
}
