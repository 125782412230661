// Grid system
.main {
  @include make-sm-column($main-sm-columns);
  .sidebar-primary & {
    @include make-sm-column($main-sm-columns - $sidebar-sm-columns);
  }
}
.sidebar {
  @include make-sm-column($sidebar-sm-columns);
}

body {
  > .container-fluid {
    //padding-left: 0;
    //padding-right: 0;
  }
}

.center-full-width {
  @include container-fixed();

  @media (min-width: $grid-float-breakpoint) {
    width: $container-sm - $grid-gutter-width;
    margin: 0 auto;
  }
  @media (min-width: $screen-md-min) {
    width: $container-md - $grid-gutter-width;
  }
  @media (min-width: $screen-lg-min) {
    width: $container-lg - $grid-gutter-width;
  }
}


// 5 columns in a row
.col-xs-5th {
  @include make-xs-column(2.4);
}
.col-sm-5th {
  @include make-sm-column(2.4);
}
.col-md-5th {
  @include make-md-column(2.4);
}
.col-lg-5th {
  @include make-lg-column(2.4);
}
