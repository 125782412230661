.property-details-container {
  @include text-align-center($alignment-min-size: 'all');
  @include text-align-left($alignment-min-size: 'md');
  
  .property-logo {
    @include img-responsive($display: inline-block);
  }

  ul.property-features {
    @include list-unstyled();
    padding: 40px 0;

    @media (min-width: $screen-md-min) {
      padding: 0;
    }

    li {
      font-weight: bold;
      font-family: $font-family-serif;
      font-style: italic;
      text-transform: capitalize;
      color: $brand-primary;
      border-bottom: 1px solid $brand-primary;
      padding: 10px 0;

      @media (min-width: $screen-md-min) {
        padding: 15px 0;
      }

      svg {
        width: 64px;
        height: 64px;
        vertical-align: middle;

        @media (min-width: $screen-md-min) {
          width: 32px;
          height: 32px;
        }
      }

      span {
        display: block;

        @media (min-width: $screen-md-min) {
          display: inline;
        }
      }
    }
  }
}
